@font-face {
  font-family: 'Rubik';
  src: local('Rubik Regular'), url('assets/fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik Regular Italic'), url('assets/fonts/Rubik-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik Bold'), url('assets/fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik Mono One';
  src: local('Rubik Mono One Regular'), url('assets/fonts/RubikMonoOne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Mono';
  src: local('Noto Sans Mono Regular'), url('assets/fonts/NotoSansMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
