.search-result-drop .drop-info {
  display: flex;
  padding: .5rem;
  gap: 1rem;
  margin: 0;
  height: auto;
  justify-content: inherit;
  position: relative;
}

.search-result-drop .drop-info .drop-image {
  overflow: hidden;
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.search-result-drop .drop-info .drop-image .drop-link {
  display: block;
}

.search-result-drop .drop-info .drop-name {
  flex-grow: 1;
  border-radius: 3px;
}

.search-result-drop .drop-info .drop-name h4 {
  max-width: 360px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  padding: 2px;
  position: relative;
}

.search-result-drop .drop-info .drop-select {
  text-align: center;
}
