.warning-icon .icon {
  font-size: 24px;
  color: #F4E2A1;
  border-bottom: 1px dotted #D4BE74;
  cursor: help;
}

.warning-icon .icon::before {
  content: "⚠";
}
