.button-menu {
  background-color: white;
}

.button-menu .button-menu-content {
  position: absolute;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
  overflow: hidden;
  padding-top: .5rem;
}

.button-menu:hover .button-menu-content {
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  animation: fade .5s linear forwards;
}

@keyframes fade {
  from {
    height: 0;
    opacity: 0;
    transform: scaleY(0);
  }
  to {
    height: auto;
    opacity: 1;
    transform: scaleY(1);
  }
}
