.footer {
  display: flex;
  gap: .5rem;
  margin: 1rem;
  justify-content: center;
}

.footer .dot {
  display: inline;
  font-size: 14px;
  line-height: 1;
}

.footer .version, .footer .version a {
  color: #a1a1a1;
}
.footer .version a:hover {
  color: #666;
  text-decoration: none;
}

.footer .link, .footer .text {
  font-size: 12px;
}

.footer .link, .footer .link:active {
  color: blue;
}

.footer .link:hover {
  text-decoration: underline;
}
