.drop-header {
  display: flex;
  gap: .5rem;
}

.drop-header .drop-header-details .drop-id {
  font-weight: bold;
  line-height: 1.6;
  color: black;
}

.drop-header .drop-header-details h2 {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
