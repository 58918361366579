.addresses-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: .5rem;
}

.addresses-form textarea {
  width: 100%;
  padding: .2rem;
}

.addresses-form .addresses-form-actions {
  position: absolute;
  top: 12px;
  right: 24px;
}
