.checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: .15rem;
  margin: 0 .5rem;
  border: .15rem solid #6692A4;
  outline: none;
  cursor: pointer;
}

.checkbox:checked {
  background-color: #6692A4;
  position: relative;
}

.checkbox:checked::before {
  content: "\2714";
  font-size: 1.1rem;
  color: white;
  position: absolute;
  right: 0;
  top: -3px;
}

.checkbox:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox:focus {
  box-shadow: 0 0 .5rem #007a7e;
}
