.ens-avatar {
  border-radius: 1rem;
  border-width: 0;
  width: 128px;
  height: 128px;
  background-color: #efefef;
  overflow: hidden;
}

.ens-avatar .loading {
  position: relative;
  top: 48px;
  left: 48px;
}

.ens-avatar .error-message {
  border-radius: 1rem;
  text-align: center;
  width: 100%;
  height: 100%;
}

.ens-avatar .error-message .message {
  font-size: 28px;
}
