.card {
  position: relative;
  margin: 1rem;
}

.card::before {
  content: "";
  position: absolute;
  top: .8rem;
  left: -.6rem;
  height: 100%;
  width: 100%;
  border-radius: 3.2rem;
  background-color: gray;
  opacity: .25;
}

.card .card-content {
  position: relative;
  border: .1rem solid black;
  border-radius: 3.2rem;
  background-color: white;
  padding: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.card .card-content.fat {
  padding: 4.5rem;
}

.card .card-content.shink {
  flex-shrink: 1;
}
