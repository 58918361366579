.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
}

.button-group.right {
  flex-direction: row-reverse;
}

.button-group.vertical {
  flex-direction: column;
  gap: .5rem;
}
