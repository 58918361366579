.logo-menu-wrap {
  position: sticky;
  top: 1rem;
}

.logo-menu .logo-menu-card {
  border: 1px solid transparent;
  border-radius: 3.25rem;
}

.logo-menu .logo-menu-card:hover {
  background-color: white;
  border-color: #5e58a5;
}

.logo-menu .logo-menu-content {
  display: none;
  padding: 0 .7rem .7rem .7rem;
}

.logo-menu .logo-menu-card:hover .logo-menu-content {
  display: block;
}
