.drop-info {
  position: relative;
}

.drop-info .drop-info-details {
  display: flex;
}

.drop-info .drop-info-details .drop-info-data {
  margin-left: 1rem;
}

.drop-info .drop-info-extra {
  position: absolute;
  top: 4rem;
  left: calc(100% - 4rem);
  bottom: 4rem;
  min-width: 4rem;
  background-color: white;
}

.drop-info .drop-info-extra .drop-info-extra-card {
  position: relative;
  width: 100%;
  height: 100%;
  border: .1rem solid black;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  background-color: white;
  overflow: hidden;
}

.drop-info .drop-info-extra .drop-info-extra-card::before {
  content: "";
  position: absolute;
  top: .8rem;
  left: -.6rem;
  height: 100%;
  width: 100%;
  border-radius: 3.2rem;
  background-color: gray;
  opacity: .25;
  z-index: -1;
}

.drop-info .drop-info-extra .drop-info-extra-button {
  border-width: 0;
  background-color: lightgray;
  cursor: pointer;
  outline: 0;
}

.drop-info .drop-info-extra.open .drop-info-extra-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2.7rem;
}

.drop-info .drop-info-extra.close .drop-info-extra-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}

.drop-info .drop-info-extra .drop-info-extra-content {
  padding: 1rem .5rem 1rem 0;
  margin: 0 .5rem 0 5rem;
  height: 100%;
  max-width: 100rem;
  min-width: 20rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.drop-info .drop-info-extra .drop-info-extra-content p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  word-wrap: break-word;
}
