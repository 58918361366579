.address-collector .address,
.address-collector .ens {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address-collector.big-address .address {
  font-size: 160%;
}

.address-collector.big-ens .ens {
  font-size: 160%;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .address-collector .address.short {
    display: block !important;
  }
  .address-collector .address.long {
    display: none !important;
  }
}
