.page {
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.page .page-aside {
  width: 4.2rem;
  z-index: 99;
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.page .page-content {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
}

.page .page-content .corner-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -99;
}

@media only screen and (min-width: 1024px) {
  .page .page-aside {
    position: inherit;
    margin: .5rem 1rem 0 0;
  }

  .page .page-content {
    width: calc(100% - 5.2rem);
  }

  .logo-menu .logo-menu-card {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .logo-menu .logo-menu-card .logo-menu-content {
    display: block !important;
  }

  .logo {
    border: 1px solid transparent;
    border-radius: 3.25rem;
  }

  .logo:hover {
    background-color: white;
    border-color: #5e58a5;
  }
}
