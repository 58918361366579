.stats {
  display: inline-block;
  border: .35rem solid white;
  border-radius: 1.62rem;
  margin-top: .4rem;
  margin-bottom: .5rem;
  background-color: white;
}

.stats.highlighted {
  border-color: #efeeff;
}

.stats.highlighted .stat.common {
  margin-left: .5rem;
}

.stats.highlighted .stat.common:first-child {
  padding-left: .5rem;
}

.stats.highlighted .stat.common:last-child {
  padding-right: .5rem;
}

.stats .stats-content {
  margin: -.35rem;
  display: flex;
}

.stat {
  padding: .35rem 0;
  border: .35rem solid transparent;
  border-radius: 1.62rem;
  align-self: center;
}

.stat.highlight {
  padding-left: .5rem;
  padding-right: .5rem;
  border-color: #efeeff;
  background-color: #efeeff;
}

.stat.with-link:hover a.stat-name {
  text-decoration: underline;
}

.stat .stat-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 60px;
  gap: .1rem;
}

.stat .shadow-text {
  margin: 0 auto;
}

.stat .stat-name {
  margin-top: .8rem;
  background-color: white;
  padding: 1px 3px;
  border-radius: 7px;
  white-space: nowrap;
  color: black;
}

.stat .stat-name-medium {
  margin-top: .5rem;
}

.stat .stat-name-small {
  margin-top: .2rem;
}

.stat span.stat-name[title] {
  cursor: help;
  text-decoration: underline dotted;
}
