.drop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.drop-header-info {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.drop .caching,
.drop .caching-error,
.drop .cached {
  font-size: 11px;
  display: inline-block;
  background-color: white;
  padding: 1px 3px;
  border-radius: 7px;
  line-height: 1.8;
  margin-top: 3px;
}

.drop .caching-error .caching-error-label {
  font-size: 7px;
  border-radius: 7px;
  padding: 2px 6px;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  background-color: #E9A1B6;
  color: #F8D1D2;
  border: 1px dashed #e76e90;
  margin-right: .5rem;
}
