.token-image-zoom .token-image-zoom-in-button {
  appearance: none;
  outline: none;
  border-width: 0;
  background-color: transparent;
  cursor: zoom-in;
}

.token-image-zoom-modal .token-image-zoom-out-button {
  appearance: none;
  outline: none;
  border-width: 0;
  background-color: transparent;
  cursor: zoom-out;
}

.token-image-zoom-modal .button-close {
  position: absolute;
  top: 12px;
  right: 24px;
}

.token-image-zoom-loading .loading {
  position: absolute;
  top: 256px;
  left: 256px;
}

.token-image-zoom-error .error-message {
  margin: 0 auto;
}
