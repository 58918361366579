.drops-collectors .drops-collectors-chunks {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-bottom: .5rem;
  scroll-margin-bottom: .5rem;
}

.drops-collectors .drops-collectors-chunks ul {
  list-style-type: none;
  flex: 0 0 auto;
}

.drops-collectors .drops-collectors-chunks ul:not(:last-child) {
  border-right: 1px dotted black;
  margin-right: .5rem;
}

.drops-collectors .drops-collectors-chunks ul li {
  margin: .1rem .1rem .1rem 0;
  padding: .2rem .4rem;
}

.drops-collectors .drops-collectors-chunks ul li:hover {
  background-color: #EEE;
}
