.center-page {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-page > * {
  margin-left: auto !important;
  margin-right: auto !important;
}
