.last-events {
  padding: .1rem;
}

.last-events .button-refresh {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.last-events .per-page {
  text-align: right;
}

.last-events .per-page .label {
  text-transform: lowercase;
  font-size: 80%;
  vertical-align: middle;
}
