.loading {
  display: inline-block;
}

.loading .show-details {
  appearance: none;
  outline: none;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 15px;
  border-width: 0;
}

.loading .loading-inner {
  position: relative;
}

.loading.big .loading-inner {
  padding: 2rem;
}

.loading .progress, .loading .count {
  z-index: 5;
  display: block;
  position: absolute;
  top: 30px;
  text-align: center;
  width: 100%;
  margin-left: 4px;
  font-weight: bold;
  font-size: 15px;
}

.loading .total {
  z-index: 5;
  display: block;
  position: absolute;
  top: 48px;
  text-align: center;
  width: 100%;
  margin-left: 4px;
  text-decoration: overline;
}

.loading .total.final {
  font-weight: 700;
}

.loading .details {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
}

.loading .details .eta,
.loading .details .rate {
  white-space: nowrap;
  display: block;
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  transform: translateX(-6px) translateY(-6px);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #473E6B;
  border-color: #473E6B transparent #473E6B transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading.medium {
  margin: 0;
}

.loading.medium .count {
  top: 20px;
}

.loading.medium .total {
  top: 38px;
}

.loading.medium .lds-dual-ring {
  width: 48px;
  height: 48px;
}

.loading.medium .lds-dual-ring:after {
  width: 44px;
  height: 44px;
  border-width: 4px;
  margin: 4px;
}

.loading.small {
  margin: 0;
}

.loading.small .count {
  top: 10px;
}

.loading.small .total {
  top: 18px;
}

.loading.small .lds-dual-ring {
  width: 30px;
  height: 30px;
}

.loading.small .lds-dual-ring:after {
  width: 28px;
  height: 28px;
  border-width: 3px;
  margin: 3px;
}

.loading.icon {
  margin: 0;
}

.loading.icon .count {
  top: 5px;
}

.loading.icon .total {
  top: 10px;
}

.loading.icon .lds-dual-ring {
  width: 16px;
  height: 16px;
}

.loading.icon .lds-dual-ring:after {
  width: 15px;
  height: 15px;
  border-width: 2px;
  margin: 0;
}

.loading h4:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1rem;
  }
}
