.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, .75);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 99;
}

.modal {
  max-height: 100%;
  max-width: 100%;
  outline: none;
}
