.button-link {
  background-color: transparent;
  border-width: 0;
  color: blue;
  cursor: pointer;
}

.button-link:hover {
  text-decoration: underline;
}

.button-link[disabled] {
  color: black;
  cursor: default;
}

.button-link[disabled]:hover {
  text-decoration: none;
}
