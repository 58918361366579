* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, input, button, select, option, textarea {
  line-height: 1.15;
  font-family: 'Rubik', sans-serif;
}

code, .mono {
  font-family: 'Noto Sans Mono', monospace;
}

html, body, #root, .app {
  height: 100vh;
  width: 100vw;
  cursor: default;
}

body {
  font-size: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
}

input, button, select, option, textarea {
  font-size: 80%;
}

a {
  outline: 0;
  text-decoration: none;
}

a:not(.disabled):not(.loading):hover {
  text-decoration: underline;
}

a.disabled, a.loading {
  pointer-events: none;
}

p {
  margin-bottom: .134rem;
}

input[type=text]::-ms-clear,
input[type=text]::-ms-reveal {
  display: none;
  width : 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: .5rem;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #5e58a5;
  -webkit-box-shadow: 0 0 2px #cdcdcd;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7d73e2;
  cursor: move;
}

.skeleton {
  position: relative;
  background: #CCC;
}

.skeleton:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(90deg, transparent, #ffffff33, transparent);
  animation: skeleton 0.8s infinite;
}

@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}
