.status {
  border-radius: 7px;
  padding: 6px;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
}

.status-loading {
  background-color: #F4E2A1;
  border: 1px dashed #D4BE74;
}

.status-error {
  background-color: #E9A1B6;
  color: #F8D1D2;
  border: 1px dashed #e76e90;
}
