.welcome {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-width: 23rem;
}

.welcome::before {
  content: "";
  position: absolute;
  top: .8rem;
  left: -.6rem;
  height: 100%;
  width: 100%;
  border-radius: 3.2rem;
  background-color: gray;
  opacity: .25;
}

.welcome .welcome-content {
  position: relative;
  background-color: #C2E6F5;
  border: 1px solid #6692A4;
  padding: 1.5rem;
  border-radius: 3.2rem;
}

.welcome .welcome-content .link {
  background-color: transparent;
  border-width: 0;
  color: blue;
  cursor: pointer;
}

.welcome .welcome-content .link:hover {
  text-decoration: underline;
}
