.search {
  min-width: 23rem;
}

.search-form {
  position: relative;
}

.search-form input.query {
  background-color: #B5AEFF;
  border: 0;
  border-radius: 3.2rem;
  color: black;
  padding: 20px;
  width: 100%;
  padding-right: 100px;
}

.search-form input.query:focus {
  outline: none;
}

.search-form input.query::placeholder {
  color: white;
}

.search-form input.go {
  -webkit-appearance: none;
  appearance: none;
  background-color: #6692A4;
  background: linear-gradient(to bottom, #6692A4 0%);
  border: 0;
  border-radius: 3.2rem;
  color: white;
  padding: 15px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.search-form input.go:hover {
  background-color: #C2E6F5;
  color: black;
  cursor: pointer;
}

.search-form input.go[disabled] {
  background-color: lightgrey !important;
  color: white !important;
  cursor: default;
}

.search-error {
  margin-top: .5rem;
  border: 1px dotted #F8D1D2;
  border-radius: 3px;
}

.search-error p {
  padding: .5rem;
  color: #E9A1B6;
}

.search-header {
  margin-top: .5rem;
  text-align: right;
}

.search-header h3 {
  display: inline-block;
  margin-left: .5rem;
}

.search-header h3.soft {
  font-weight: normal;
  font-size: 90%;
}

.search-separator {
  border: 1px solid #6692A4;
  margin: .5rem 0;
}

.search-pagination {
  margin-top: .5rem;
}

.search-options {
  text-align: center;
}

.search-options .link, .search-options .text {
  font-size: 12px;
}

.search-options .link, .search-options .link:active {
  color: blue;
}

.search-options .link:hover {
  text-decoration: underline;
}

.search-result {
  border: 1px dotted #B5AEFF;
  margin-top: .5rem;
  position: relative;
  overflow: hidden;
}

.search-result .search-result-info {
  display: flex;
  padding: .5rem;
  gap: 1rem;
  margin: 0;
  height: auto;
  justify-content: inherit;
  position: relative;
}

.search-result .search-result-info .search-result-image {
  overflow: hidden;
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.search-result .search-result-info .search-result-name {
  flex-grow: 1;
  border-radius: 3px;
}
