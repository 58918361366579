.event-in-common-count-list {
  list-style-type: none;
  overflow: auto;
}

.event-in-common-count-list li {
  margin: .5rem 0;
}

.event-in-common-count-list li:hover {
  cursor: pointer;
  background-color: #B5AEFF;
}

.event-in-common-count {
  border: 1px dotted #B5AEFF;
  padding: 1rem;
  display: flex;
  gap: 1rem;
}

.event-in-common-count .event-in-common-count-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-in-common-count .event-in-common-count-card .event-id {
  text-align: center;
  display: block;
  cursor: pointer;
  font-size: 80%;
  margin: 1px;
}

.event-in-common-count .event-in-common-count-info h4 {
  display: inline-block;
  max-width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-in-common-count .event-in-common-count-info .event-in-common-count-data {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: .5rem;
}

.event-in-common-count .event-in-common-count-info .event-in-common-count-data .in-common-count {
  font-weight: 700;
  font-size: 120%;
}

.event-in-common-count .event-in-common-count-info .event-in-common-count-data .cached-ts {
  font-size: 11px;
}
