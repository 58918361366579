.address-profile {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}

.address-profile .profile-address {
  word-break: break-all;
}

.address-profile .profile-ens {
  font-size: 160%;
}

.address-profile .profile-since {
  font-weight: 400;
}

.address-profile .profile-poaps,
.address-profile .profile-in-common {
  margin-top: 2rem;
  min-width: 100%;
  max-width: 32rem;
}

.address-profile .profile-poaps img,
.address-profile .profile-in-common img {
  margin: .1rem;
}

.address-profile h4 a {
  color: black;
}
